import React from "react"
import { StoryGroup } from "components"

const LinkMap = {
  "Game Mechanics": "25ef65a8733f",
  Graphics: "25ef65a8733f",
  Resources: "25ef65a8733f",
  "Game Model": "25ef65a8733f",
  Inputs: "25ef65a8733f",
  "Sound And Musics": "25ef65a8733f",
  AI: "25ef65a8733f",
  Network: "25ef65a8733f",
  Hardware: "25ef65a8733f",
  GraphicCard: "25ef65a8733f",
  GPU: "25ef65a8733f",
  "Vertex Shader": "25ef65a8733f",
  "Pixel Shader": "25ef65a8733f",
  OS: "25ef65a8733f",
  "Process Management": "25ef65a8733f",
  "Memory Management": "25ef65a8733f",
  "File Management": "25ef65a8733f",
  "I/O Management": "25ef65a8733f",
  "Distributed System": "25ef65a8733f",
  CLI: "25ef65a8733f",
  "Programming Language": "25ef65a8733f",
  Assembly: "25ef65a8733f",
  "C++": "25ef65a8733f",
  "What's API": "25ef65a8733f",
  OpenGL: "25ef65a8733f",
  "OpenGL State Machine": "25ef65a8733f",
  "OpenGL Query": "25ef65a8733f",
  "Rendering Pipeline": "25ef65a8733f",
  "Rendering-DisplayList": "25ef65a8733f",
  "Rendering-Evaluators": "25ef65a8733f",
  "Rendering-PerVertexOps": "25ef65a8733f",
  "Rendering-Primitive Assembly": "25ef65a8733f",
  "Rendering-PixelOperations": "25ef65a8733f",
  "Rendering-TextureAssembly": "25ef65a8733f",
  "Rendering-Rasterization": "25ef65a8733f",
  "Rendering-FragmentOperation": "25ef65a8733f",
  GLUT: "25ef65a8733f",

  "Pixel Game": "4638bc135acc",
  "Levenshtein distance": "4638bc135acc",

  "İş Zekası(BI)": "275eb8f56168",
  "BI-Excel Seviyesi": "275eb8f56168",
  "BI-Operasyonel Raporlar": "275eb8f56168",
  "BI-Query & Analysis": "275eb8f56168",
  "BI-Dashboard": "275eb8f56168",
  "BI-OLAP": "275eb8f56168",
  "BI-Data Mining": "275eb8f56168",
  "BI-Predictive Analysis": "275eb8f56168",
  Tableau: "275eb8f56168",
  "Power BI": "275eb8f56168",
  QlikView: "275eb8f56168",
  "SAP Lumira": "275eb8f56168",
  Redash: "275eb8f56168",
  Quicksight: "275eb8f56168",
  "IBM Cognos": "275eb8f56168",

  "Game Analysis": "80116fd69db7",
}

const storyGame = [
  {
    title: "Oyun Geliştirmenin Temelleri",
    postId: "25ef65a8733f",
  },
  {
    title: "Basit Bir Oyun: Resimdeki Karakteri Bul",
    postId: "4638bc135acc",
  },
  {
    title: "İş Zekası(BI) ve Olgunluk Modeli",
    postId: "275eb8f56168",
  },
  {
    title: "Basit Oyunları Analiz Edelim",
    postId: "80116fd69db7",
  },
]

export const context = {
  stories: storyGame,
  linkMap: LinkMap,
  title: "Algoritma - Oyun Geliştirme",
  path: "game-and-bi",
}

const GameAndBIPage = () => {
  return (
    <StoryGroup
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
    />
  )
}

export default GameAndBIPage
